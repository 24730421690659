import { Page } from '@geist-ui/react'
import styles from './Home.module.css'
import News from '../News'
//import Products from '../Products'
import Highlights from '../Highlights'
import FAQ from '../FAQ'
//import Customers from '../Customers'
//import AboutUs from '../AboutUs'
import Privacy from '../Privacy'
import HowItWorks from '../HowItWorks'
import Footer from '../Footer'
import Header from '../Header'
import { BurgerMenu } from '../BurgerMenu'
import { Contact } from '../Contact'
import Divider from '../Divider'

const Home = () => {
  return (
    <Page render="effect-seo" className={styles.pageContainer}>
      <Page.Header className={styles.header}>
        <Header />
      </Page.Header>
      <Page.Content className={styles.pageContent}>
        <BurgerMenu />
        <Highlights />
        {/* <Divider title="Products" />
        <Products /> */}
        {/* <Divider title="Customers" />
        <Customers /> */}
        {/* <Divider title="About us" />
        <AboutUs /> */}
        <Divider title="How our reusable take-away works" />
        <HowItWorks />
        <Divider title="Frequently asked questions" />
        <FAQ />
        <Divider title="Contact" />
        <Contact />
      </Page.Content>

      <Page.Footer
        style={{
          bottom: 'inherit',
          borderTop: 'solid 2.4px #eaeaea',
          position: 'sticky',
          background: 'white',
          zIndex: 2,
        }}
        className={styles.footerWrapper}
      >
        <Footer />
      </Page.Footer>
    </Page>
  )
}

export default Home
