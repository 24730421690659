import { Text, Image, Grid } from '@geist-ui/react'
import styles from './FAQ.module.css'

const faq = () => {
  return (
    <div id="faq">
      <div className={styles.faq}>
        
          <Text p className={styles.faqAnswer}>
          <p>
            <b>Question: How to find the restaurants that provide the service?</b> <br></br>
            <b>Answer:</b> You can find all the partner restaurants in our mobile app "reConServ".
          </p>
          <p>
            <b>Question: Should I pay to borrow the bowls?</b> <br></br>
            <b>Answer:</b> Good news! You need not pay to start using our reConServ bowls.
          </p>
          <p>
            <b>Question: Can I borrow multiple bowls?</b> <br></br>
            <b>Answer:</b> Yes, you can borrow a maximum of 5 bowls.
          </p>
          <p>
            <b>Question: Should I wash the bowls before I return?</b> <br></br>
            <b>Answer:</b> To be considerate of our washing staff, we would appreciate if you could at least rinse the bowls.
          </p>
          <p>
            <b>Question: What happens if I do not return the bowls within 14 days?</b> <br></br>
            <b>Answer:</b> You cannot borrow more bowls until you do the following, you can either extend the loan period for 7 more days by paying 10 SEK or buy the package for 150 SEK.
          </p>
          <p>
            <b>Question: Can you provide the Product information?</b> <br></br>
            <b>Answer:</b> Bowl is made of Polypropylene (PP), Microwave safe (max 100°C up to 10 minutes), dishwasher safe (500 cycles), 100% BPA free and Made in Europe. Works for both hot and cold foods.
          </p>
          <p>
            <b>Question: How can I give feedback or register a complaint about the service?</b> <br></br>
            <b>Answer:</b> We are happy to receive your comments on our email info@conserv.se.
          </p>
          </Text>

      </div>
    </div>
  )
}

export default faq
