import { Text, Image, Grid } from '@geist-ui/react'
import styles from './HowItWorks.module.css'

const HowItWorks = () => {
  return (
    <div id="howitworks">
      <div className={styles.howitworks}>
          {/* <Image
            className={styles.processImage}
            style={{ borderRadius: 20, objectFit: 'contain' }}
            src="/assets/howitworks/workers_pic.jpg"
            loading="lazy"
          /> */}

          <Text p className={styles.problemSummary}>
            How it works for Restaurants
          </Text>

          <div className={styles.problemContainer}>
            <div className={styles.problem}>
              <a
                href="https://www.flaticon.com/free-icons/bowls"
                target="_blank"
                rel="noreferrer"
                title="Bowls icons created by surang - Flaticon"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/clean_bowl.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                We monitor your inventory through our mobile app so you always have clean reConServ bowls for your takeaways.
              </Text>
            </div>

            <div className={styles.problem}>
            <a
                href="https://www.flaticon.com/free-icons/qr-code"
                target="_blank"
                rel="noreferrer"
                title="Qr code icons created by small.smiles - Flaticon"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/qr_code_scan.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                You lend the reConServ bowl to consumers after the consumer scans the QR code.
              </Text>
            </div>

            <div className={styles.problem}>
              <a
                href="https://www.flaticon.com/free-icons/fast-delivery"
                title="Fast-delivery icons created by Freepik - Flaticon"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/fast-delivery.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                We pick up bowls returned by the consumers from drop-off box. It will be washed and sanitized for reuse.
              </Text>
            </div>
          </div>


          <Text p className={styles.problemSummary}>
            How it works for Consumers
          </Text>

          <div className={styles.problemContainer}>
            <div className={styles.problem}>
              <a
                href="https://www.flaticon.com/free-icons/qr-code"
                target="_blank"
                rel="noreferrer"
                title="Qr code icons created by small.smiles - Flaticon"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/qr_code_scan.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                Scan QR code at a partner restaurant to borrow the reConServ bowls at no cost.
              </Text>
            </div>

            <div className={styles.problem}>
              <a
                href="https://www.flaticon.com/free-icons/meal"
                title="Meal icons created by Payungkead - Flaticon"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/enjoy_food.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                Enjoy your food with our reConServ bowls. You have 14 days to return the bowls.
              </Text>
            </div>

            <div className={styles.problem}>
              <a
                href="https://www.flaticon.com/free-icons/rubbish"
                title="Rubbish icons created by gravisio - Flaticon"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  width="100px"
                  height="100px"
                  src="/assets/howitworks/smart-trash.png"
                  loading="lazy"
                />
              </a>

              <Text p className={styles.problemInfo}>
                You can return the reConServ bowls by scanning QR code at drop-off bin in any of our partner restaurants.<br></br>
                If not returned within 14 days, you can either extend the return for 7 more days by paying <b>10 SEK</b> or buy the package for <b>150 SEK</b>.
              </Text>
            </div>
          </div>



          {/* <Image
            className={styles.processImage}
            style={{ borderRadius: 20, objectFit: 'contain' }}
            src="/assets/howitworks/conserv_howitsmade.jpg"
            loading="lazy"
          /> */}
          
        </div>
      </div>
  )
}

export default HowItWorks
