import { Text, Image } from '@geist-ui/react'
import { Carousel } from '../Carousel'
import styles from './Highlights.module.css'
import { ProductImage } from '../ProductImage'
import { landingPage } from '../../_landingPage'

const Highlights = () => {
  return (
    <>
      <div className={styles.conservHighlights}>
        {/* ConServ product images */}
        <Carousel>
          {landingPage.productImages.map((imageSource) => (
            <ProductImage imageSource={imageSource} />
          ))}
        </Carousel>

        <div className={styles.problemContainer}>
          <div className={styles.problem}>
            <a
              href="https://play.google.com/store/apps/details?id=com.reConServ.app"
              target="_blank"
              rel="noreferrer"
              title="contamination icons"
            >
              <Image
                width="250px"
                height="150px"
                src="/images/google_play.png"
                loading="lazy"
              />
            </a>
          </div>

          <div className={styles.problem}>
            <a
              href="https://apps.apple.com/us/app/reconserv/id6741072982"
              title="wood icons"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                width="250px"
                height="150px"
                src="/images/app_store.png"
                loading="lazy"
              />
            </a>
          </div>
        </div>

        <div className={styles.conservTagline}>
          <Text className={styles.sustainableText} h1>
            Sustainable.
          </Text>
          <Text className={styles.consciousText} h1>
            Circular.
          </Text>
          <Text className={styles.futureText} h1>
            Future.
          </Text>
        </div>
        
        {/* About us section */}

      </div>
    </>
  )
}

export default Highlights
