import { Image, Grid, Text } from '@geist-ui/react'
import styles from './Header.module.css'
import BurgerIcon from './BurgerIcon'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <Grid.Container gap={2} justify="space-around" alignItems="center">
      <Grid marginLeft={0} xs={11}>
        <Link to={`/`} style={{ textDecoration: 'none' }}>
          <Image
            src="/images/reConServ_logo.png"
            width={14}
            height={5}
          />
        </Link>
      </Grid>
      <Grid xs={10} className={styles.headerLinksContainer}>
        {/* <Text p className={styles.headerItems}>
          <Link to={`/aboutus`} style={{ textDecoration: 'none' }}>
            About us
          </Link>
        </Text> */}
        {/* <Text p className={styles.headerItems}>
          <Link to={`/all-products`} style={{ textDecoration: 'none' }}>
            Products
          </Link>
        </Text> */}
        <Text p className={styles.headerItems}>
          <Link to={`/howitworks`} style={{ textDecoration: 'none' }}>
            How it works
          </Link>
        </Text>
        {/* <Text p className={styles.headerItems}>
          <Link to={`/customers`} style={{ textDecoration: 'none' }}>
            Customers
          </Link>
        </Text> */}
        <Text p className={styles.headerItems}>
          <Link to={`/faq`} style={{ textDecoration: 'none' }}>
            FAQ
          </Link>
        </Text>
        <Text p className={styles.headerItems}>
          <Link to={`/contact`} style={{ textDecoration: 'none' }}>
            Contact
          </Link>
        </Text>
      </Grid>

      <Grid marginLeft={0} className={styles.headerIcon} xs={3}>
        <BurgerIcon />
      </Grid>
    </Grid.Container>
  )
}

export default Header
